import React from 'react'
import PropTypes from 'prop-types'

import './Stack.scss'

const Stack = ({ children }) => {
  return <div className="comp-stack">{children}</div>
}

Stack.propTypes = {
  children: PropTypes.array.isRequired,
}

export default Stack

import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Stack from '../components/Stack';

import './index.scss';

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "images/thomas-lumpp.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 390, quality: 90, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <div>
      <SEO
        title="Thomas Lumpp – Heilpraktiker in Stuttgart"
        description="Psychotherapie und Schmerztherapie in Stuttgart – Online Termine und Hausbesuche sind möglich"
        useTemplate={false}
      />
      <Layout>
        <Stack>
          <Container className="page-index__content">
            <Row>
              <Col md={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 1 }} className="page-index__portrait-col">
                <Img fadeIn fluid={data.image.childImageSharp.fluid} className="page-index__portrait" />
              </Col>
              <Col md={{ span: 7, offset: 0 }} lg={{ span: 5, offset: 0 }} className="page-index__contact-col">
                <h2>Ich freue mich auf Ihren Kontakt</h2>
                <p>
                  Telefon: <a href="tel:+4917670392053">+49 (0) 176 – 70 39 20 53</a>
                  <br />
                  E-Mail: <a href="mailto:info@thomaslumpp.de">info@thomaslumpp.de</a>
                  <br />
                  FB:{' '}
                  <a href="https://www.facebook.com/thomaslumpp.de" rel="noopener noreferrer" target="_blank">
                    facebook.com/thomaslumpp.de
                  </a>
                </p>

                <h2 className="mt-3">Anschrift</h2>
                <p>
                  Thomas Lumpp
                  <br />
                  Kronenstraße 34
                  <br />
                  70174 Stuttgart
                  <br />
                  <a href="https://goo.gl/maps/g5ppH4vN66epLAsd6" rel="noopener noreferrer" target="_blank">
                    In Google Maps öffnen
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </Stack>
      </Layout>
    </div>
  );
};

export default IndexPage;
